const audios = [
    {
        name: "Heather x Eyes Blue",
        src: require('./lofi6.mp3'),
    },
    {
        name: "Lofi tan truong",
        src: require('./lofi1.mp3'),
    },
    // {
    //     name: "Lofi Chill VN",
    //     src: require('./lofi3.mp3'),
    // },
    {
        name: "Lofi khong gion",
        src: require('./lofi2.mp3'),
    },
    {
        name: "Lofi first date",
        src: require('./lofi4.mp3'),
    },
    {
        name: "Trách Phận Vô Danh (Lofi Ver)",
        src: require('./lofi5.mp3'),
    },
    {
        name: "Nhớ Về Em (Lofi Music)",
        src: require('./lofi7.mp3'),
    },

];

export default audios;